<template>
  <div>
    <v-app-bar
      clipped-left
      clipped-right
      app
      class="px-0 .d-flex"
      color="background"
    >
    <!-- Only show if the team is connected -->
      <v-app-bar-nav-icon @click="drawer = true"></v-app-bar-nav-icon>

      <router-link
        style="height: 40px"
        to="/admin"
        tag="img"
        :src="require('@/assets/rallylogga_svart.png')"
      />

      <v-spacer></v-spacer>
      ADMIN
      <v-icon>mdi-shield-crown</v-icon>
    </v-app-bar>

    <v-navigation-drawer class="sideBar"
      v-model="drawer"
      fixed
      temporary
    >
      <v-list
        nav
        dense
      >
        <v-list-item-group
          v-model="group"
          active-class="active-item"
        >

          <v-list-item to="/admin" exact class="listItem">
            <v-list-item-icon>
              <v-icon>mdi-monitor-dashboard</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Hem</v-list-item-title>
          </v-list-item>

          <v-divider/>
          <p class="font-weight-black leftMenuHeading">Under Rallyt</p>
          <v-list-item to="/admin/steel" class="listItem">
            <v-list-item-icon>
              <v-icon>mdi-flag-checkered</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Start, Mål + Hantera lag</v-list-item-title>
          </v-list-item>

          <v-list-item to="/admin/lunch" class="listItem">
            <v-list-item-icon>
              <v-icon>mdi-food</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Lunchstation</v-list-item-title>
          </v-list-item>

          <v-list-item to="/admin/progress" class="listItem">
            <v-list-item-icon>
              <v-icon>mdi-card-account-details</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Lagstatus</v-list-item-title>
          </v-list-item>

          <v-divider/>
          <p class="font-weight-black leftMenuHeading">Efter Rallyt</p>

          <v-list-item to="/admin/correction" class="listItem">
            <v-list-item-icon>
              <v-icon>mdi-checkbox-marked</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Rättning</v-list-item-title>
          </v-list-item>

          <v-list-item to="/admin/results" class="listItem">
            <v-list-item-icon>
              <v-icon>mdi-clipboard-list</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Resultat</v-list-item-title>
          </v-list-item>

          <v-list-item to="/admin/trickyAnswers" class="listItem">
            <v-list-item-icon>
              <v-icon>mdi-thought-bubble</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Kluringsvar</v-list-item-title>
          </v-list-item>
          
          <v-divider/>
          <p class="font-weight-black leftMenuHeading">Innan Rallyt</p>

          <v-list-item to="/admin/stations" class="listItem">
            <v-list-item-icon>
              <v-icon>mdi-van-passenger</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Stationer</v-list-item-title>
          </v-list-item>

          <v-list-item to="/admin/teams" class="listItem">
            <v-list-item-icon>
              <v-icon>mdi-account-group</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Lag</v-list-item-title>
          </v-list-item>

          <v-list-item to="/admin/tricky" class="listItem">
            <v-list-item-icon>
              <v-icon>mdi-thought-bubble</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Kluring</v-list-item-title>
          </v-list-item>

          <v-list-item to="/admin/other" class="listItem">
            <v-list-item-icon>
              <v-icon>mdi-database</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Annat</v-list-item-title>
          </v-list-item>

          <v-divider/>

          <v-list-item @click="signout" class="listItem">
            <v-list-item-icon>
              <v-icon>mdi-logout</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Logga ut</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
  
    <router-view />
  </div>
</template>

<script>
export default {
    name: "Admin",
    data: () => ({
      drawer: false,
      group: null,
      // loading: true // TEST
    }),
    methods: {
      signout() {
        this.$store.dispatch('signOut')
        // Avoid "redundant navigation" error message
        this.$router.push({name: 'Home'}).catch(() => {})
      },
    }
}
</script>

<style>

.sideBar{
  margin-top: 65px;
}

.leftMenuHeading{
  text-align: start;
  padding: 5px 5px 0 5px;
}

.listItem{
  text-align: start;
}

.active-item{
  background: #830017;
  color: white !important;

}





</style>